.bodyBackground{
    background-color: #2d1b4e;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='151' height='151' viewBox='0 0 200 200'%3E%3Cg %3E%3Cpolygon fill='%23430000' points='100 57.1 64 93.1 71.5 100.6 100 72.1'/%3E%3Cpolygon fill='%23480000' points='100 57.1 100 72.1 128.6 100.6 136.1 93.1'/%3E%3Cpolygon fill='%23430000' points='100 163.2 100 178.2 170.7 107.5 170.8 92.4'/%3E%3Cpolygon fill='%23480000' points='100 163.2 29.2 92.5 29.2 107.5 100 178.2'/%3E%3Cpath fill='%234D0505' d='M100 21.8L29.2 92.5l70.7 70.7l70.7-70.7L100 21.8z M100 127.9L64.6 92.5L100 57.1l35.4 35.4L100 127.9z'/%3E%3Cpolygon fill='%23210303' points='0 157.1 0 172.1 28.6 200.6 36.1 193.1'/%3E%3Cpolygon fill='%23220505' points='70.7 200 70.8 192.4 63.2 200'/%3E%3Cpolygon fill='%23230808' points='27.8 200 63.2 200 70.7 192.5 0 121.8 0 157.2 35.3 192.5'/%3E%3Cpolygon fill='%23220505' points='200 157.1 164 193.1 171.5 200.6 200 172.1'/%3E%3Cpolygon fill='%23210303' points='136.7 200 129.2 192.5 129.2 200'/%3E%3Cpolygon fill='%23230808' points='172.1 200 164.6 192.5 200 157.1 200 157.2 200 121.8 200 121.8 129.2 192.5 136.7 200'/%3E%3Cpolygon fill='%23210303' points='129.2 0 129.2 7.5 200 78.2 200 63.2 136.7 0'/%3E%3Cpolygon fill='%23230808' points='200 27.8 200 27.9 172.1 0 136.7 0 200 63.2 200 63.2'/%3E%3Cpolygon fill='%23220505' points='63.2 0 0 63.2 0 78.2 70.7 7.5 70.7 0'/%3E%3Cpolygon fill='%23230808' points='0 63.2 63.2 0 27.8 0 0 27.8'/%3E%3C/g%3E%3C/svg%3E"); 
}

.blurred2 {
    background: rgba(87, 75, 75, 0.1); /* Teljesen átlátszó háttér */ 
    box-shadow: 4px 6px 10px rgb(0, 0, 0);
    backdrop-filter: blur(0.5vw); /* Homályosítás */ 
    border: none;
}

.TextSize{
    font-size: 1vw;
}

@media (max-width: 772px) {
    .TextSize{
        font-size: 3.5vw;
    }
}
  
.kepekAtmenete {
    mask-image: linear-gradient(to top, rgba(0, 0, 0, 0) 10%, rgba(0, 0, 0, 1) 100%);
    -webkit-mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 5%, rgba(0, 0, 0, 1) 30%);
}

.kepekAtmenete2 {
    mask-image: linear-gradient(to top, rgba(0, 0, 0, 0) 45%, rgba(0, 0, 0, 1) 100%);
    -webkit-mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 40%);
}


  